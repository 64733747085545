<template>
  <div class="privacy">
    <PrivacyAndPolicyTitle />
    <PrivacyAndPolicyContent />
    <GetStarted />
  </div>
</template>

<script>
import PrivacyAndPolicyTitle from "../components/PrivacyAndPolicyTitle.vue";
import PrivacyAndPolicyContent from "../components/PrivacyAndPolicyContent.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "terms",
  components: {
    PrivacyAndPolicyTitle,
    PrivacyAndPolicyContent,
    GetStarted,
  },
  data() {
    return {
      title: "Powering digital payments and identity verification",
      description:
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Accept online payments with card, FPX and e-Wallet in less than a minute using Tekkis no-code solutions. No Tech team or website needed. Tekkis also offers API integrations for both eKYC and online payments.";
    },
  },
};
</script>
