import { render, staticRenderFns } from "./PrivacyAndPolicyContent.vue?vue&type=template&id=003164f7&scoped=true&"
var script = {}
import style0 from "./PrivacyAndPolicyContent.vue?vue&type=style&index=0&id=003164f7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003164f7",
  null
  
)

export default component.exports